export default function timeline() {
  return (
    <>
      <div className="timeline" id="experience">
        <article>
          <h2>Enzyme Testing Labs</h2>
          <p>Testeur linguistique</p>
          <p>Sainte-Adèle - Québec</p>
          <p>2011</p>
        </article>
        <article>
          <h2>Babel Media</h2>
          <p>Testeur linguistique</p>
          <p>Montréal - Québec</p>
          <p>2012</p>
        </article>
        <article>
          <h2>Activision Blizzard</h2>
          <p>Lead Testeur</p>
          <p>Dublin - Irlande</p>
          <p>2013</p>
        </article>
        <article>
          <h2>Ubisoft</h2>
          <p>Lead QA / Project Coordinator</p>
          <p>Paris - France</p>
          <p>2017</p>
        </article>
        <article>
          <h2>Formation Doranco</h2>
          <p>Développeur Web</p>
          <p>Paris - France</p>
          <p>2022</p>
        </article>
        <article>
          <h2>Formation Openclassrooms</h2>
          <p>Diplômé niv. 5 - Développeur Web</p>
          <p>Paris - France</p>
          <p>2023</p>
        </article>
      </div>
      {/* <div className="cv">
                <a href="/cv/FLORENT DUCRET Développeur Full Stack FR.pdf">👉 Télécharger mon CV 👈</a>
            </div> */}
    </>
  );
}
